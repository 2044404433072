var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout-main-part-wrap-box" }, [
    _c(
      "div",
      { staticClass: "layout-header-part-wrap-box" },
      [
        _c(
          "van-tabs",
          {
            attrs: { ellipsis: false },
            model: {
              value: _vm.tabsActive,
              callback: function ($$v) {
                _vm.tabsActive = $$v
              },
              expression: "tabsActive",
            },
          },
          _vm._l(_vm.tabsList, function (item, index) {
            return _c("van-tab", {
              key: index,
              attrs: { title: item.label, name: item.value, to: item.path },
            })
          }),
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "layout-content-part-wrap-box" },
      [_c("router-view")],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }