<template>
  <div class="layout-main-part-wrap-box">
    <!-- 头部切换部分 -->
    <div class="layout-header-part-wrap-box">
      <!--  @click="clickHandleRouterTab($event)" -->
      <van-tabs :ellipsis="false" v-model="tabsActive">
        <van-tab v-for="(item,index) in tabsList" :key="index" :title="item.label" :name="item.value" :to="item.path"></van-tab>
      </van-tabs>
    </div>
    <!-- 内容信息部分 -->
    <div class="layout-content-part-wrap-box">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      tabsList: [{
        value: 1,
        label: '客户信息',
        path: '/home',
      }, {
        value: 2,
        label: '客户跟踪任务',
        path: '/trackTask',
      }, {
        value: 3,
        label: '入店服务',
        path: '/storeRecord',
      }, {
        value: 4,
        label: '订单',
        path: '/orderInfo',
      }, {
        value: 5,
        label: '活动记录',
        path: '/activity',
      }],
      tabsActive: 1,
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    $route (to) {    //监听路由变化
      this.changePageRoute(to)
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.$nextTick(() => {
      this.changePageRoute(this.$route)
    });
  },
  // 方法集合
  methods: {
    // 点击切换选择路由切换
    clickHandleRouterTab (tab_id) {

      // this.$router.push({
      //   path: tab_item.path,
      // })
    },
    // 页面路由改变
    changePageRoute (route_info) {
      // console.log('页面路由改变', route_info)
      let tabs_obj = this.tabsList.find(item => item.path == route_info.path)
      this.tabsActive = tabs_obj ? tabs_obj.value : 1;
    },
  },
  // 生命周期 - 创建之前
  beforeCreate () { },
  // 生命周期 - 挂载之前
  beforeMount () { },
  // 生命周期 - 更新之前
  beforeUpdate () { },
  // 生命周期 - 更新之后
  updated () { },
  // 生命周期 - 销毁之前
  beforeDestroy () { },
  // 生命周期 - 销毁完成
  destroyed () { },
  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated () { },
}
</script>

<style scoped lang="scss">
.layout-main-part-wrap-box {
  width: 100vw;
  min-height: 100vh;
  // 头部切换部分
  .layout-header-part-wrap-box {
    width: 100%;
    height: 70px;
    ::v-deep .van-tabs {
      width: 100%;
      background: linear-gradient(102.84deg, #f3fbf9 0%, #f1f3f9 89.8%);
      position: fixed;
      left: 0px;
      top: 0px;
      z-index: 991;
      .van-tabs__wrap {
        height: 70px;
        .van-tabs__nav {
          background-color: rgba(255, 255, 255, 0.6);
          box-shadow: 0px -1px 0px 0px rgba(232, 236, 243, 1) inset;
          padding-bottom: 0px;
        }
      }
      .van-tab {
        padding: 0px 15px;
        font-size: 26px;
        color: #999999;
        font-weight: 700;
      }
      .van-tabs__line {
        width: 40px;
        height: 4px;
        background-color: #347fff;
        bottom: 0px;
      }
    }
  }
}
</style>
